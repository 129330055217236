.embla__horizontal {
  color: white;
  max-width: 100%;
  width: 100%;
  margin: auto;
  --slide-height: 35rem;
  --slide-spacing: 1rem;
  --slide-size: 75%;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  touch-action: pan-x pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(rgb(179, 67, 67));
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
  user-select: none;
}

.embla__slide__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 12px;
  margin-top: 12px;
  overflow: hidden;
}

.slide__title {
  font-family: "Cupra Book";
  font-size: 32px;
  text-align: center;
  letter-spacing: 1px;
}

.slide__description {
  font-family: "Cupra Regular";
  font-size: 18px;
  text-align: center;
}
