.embla__vertical {
  color: white;
  max-width: 48rem;
  margin: auto;
  --slide-height: 45rem;
  --slide-spacing: 0.1rem;
  --slide-size: 50%;
}
.embla__viewport__vertical {
  overflow: hidden;
}
.embla__container__vertical {
  display: flex;
  touch-action: pan-x pinch-zoom;
  margin-top: calc(var(--slide-spacing) * -1);
  height: calc(var(--slide-spacing) + var(--slide-height));
  flex-direction: column;
  align-items: center;
}

.embla__slide__vertical {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-height: 0;
  padding-top: var(--slide-spacing);
}
.embla__slide__number__vertical {
  box-shadow: inset 0 0 0 0.2rem var(rgb(179, 67, 67));
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  user-select: none;
}

.embla__slide__content__vertical {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  width: 600px;
  height: 300px;
}
