.design-carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.go-back-action {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: white;
  margin-top: 32px;
}

.go-back-button {
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: white;
  padding: 12px;
  cursor: pointer;
}
