.embla__button {
  -webkit-tap-highlight-color: rgba(230, 230, 230, 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  background-color: white;
  width: 3.2rem;
  height: 3.2rem;
  z-index: 1;
  border: 2px solid rgba(116, 116, 116, 0.25);
  border-radius: 50%;
  color: var(rgb(255, 255, 255));
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.embla__button--prev {
  left: 2%;
  top: 33%;
}

.embla__button--next {
  right: 2%;
  top: 33%;
}

.embla__button--prev-vertical {
  right: 10%;
  top: 32%;
  rotate: 90deg;
}

.embla__button--next-vertical {
  right: 10%;
  top: 55%;
  rotate: 90deg;
}

.embla__button:disabled {
  color: var(rgb(192, 192, 192));
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 2rem;
  gap: 24px;
  align-items: center;
  margin-top: 1rem;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(255, 244, 244, 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 3.6rem;
  height: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
  border-radius: 10%;
  border: 1px solid grey;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(rgb(225, 220, 220));
  width: 3.6rem;
  height: 0.7rem;
  border-radius: 10%;
  display: flex;
  align-items: center;
  content: "";
}
.embla__dot--selected {
  border-radius: 20%;
  background-color: white;
  height: 0.6rem;
}
